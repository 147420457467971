.images_wrap {
  float: left;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.thumbnails {
  padding-right: 10px;
  margin: 0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.slick_slider {
  position: relative;
  display: block;
  box-sizing: border-box;
}
.summary {
  padding-top: 20px;
  font-size: 1.4rem;
  text-align: center;
  overflow: visible;
  max-height: none;
}

.product_title {
  color: #1e1e23;
  text-transform: none;
  font-size: 22px;
  margin-bottom: 10px;
}

.price {
  direction: rtl;
  color: #e8730f;
  font-weight: 600;
  font-size: 1.6rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.amount {
  direction: ltr;
  display: inline-block;
  font-weight: 400;
}

.quantity {
  height: 40px;
  border: 1px solid #e4e6eb;
  display: inline-block;
}

.cart_btn {
  margin-top: -5px;
}

.sreen_reader_text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip-path: inset(50%);
  margin: -1px;
  padding: 0;
}

.form_btn {
  cursor: pointer;
  padding: 2px 6px;
  display: inline-block;
  font-size: 1.6rem;
  border: none;
  background-color: white;
}

.form_input {
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  border-width: 0;
  font-size: 1.6rem;
  color: #23232c;
  transition: border 0.5s;
  border: none;
  border-bottom: 2px solid #f1f2f4;
  border-radius: 0;
}

.whats_app_button {
  border-radius: 50vh;
  position: relative;
  width: 300px;
  margin: 20px 0;
  background: #2db742;
  cursor: pointer;
  transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
  backface-visibility: hidden;
  will-change: transform;
  min-height: 64px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #fff;
  box-shadow: 0 4px 8px 1px rgb(32 32 37 / 9%);
  -webkit-box-shadow: 0 4px 8px 1px rgb(32 32 37 / 9%);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 0 !important;
  font-weight: 600;
}

.whats_app_cs_img {
  position: absolute;
  top: 50%;
  left: -5px;

  text-align: center;

  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);

  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.whats_app_cs_img_wrap {
  width: 79px;
  height: 79px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 3px solid #fff;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.whats_app_btn_txt {
  padding: 8px 20px 8px 75px;
  display: table-cell;
  vertical-align: middle;
  height: 66px;
  position: relative;
  z-index: 4;
  font-size: 12px;
  line-height: 1.33em;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.whats_app_cs_info {
  margin-bottom: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.whats_app_cs_name {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.36em;
  color: #d5f0d9;
  opacity: 0.8;
}

.whats_app_cs_status {
  width: 36px;
  height: 14px;
  margin-left: 3px;
  padding: 1px;
  font-size: 9px;
  line-height: 1.34em;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.98);
  text-align: center;
  background: #62c971;
}

.whats_app__btn_title {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-weight: 600;
  color: black;
}

.wishlist_btn {
  display: inline-block;
  position: relative;
  transition: 0.5s;
  color: #fff !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 100%;
  font-weight: 500;
  min-width: 60px;
  border: 1px solid #e4e6eb;
}

.like {
  transition: 0.5s;
  fill: #909097;
  stroke: #909097;
  stroke-width: 0;
  overflow: hidden;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.liked {
  transition: 0.5s;
  margin-right: 0;
  fill: #909097;
  stroke: #909097;
  stroke-width: 0;
  overflow: hidden;
  width: 20px;
  height: 20px;
  stroke-width: 0;
}

.indent_text {
  display: inline-block;
  margin: 0 0 0 -5px;
  padding: 0;
  text-indent: -9999px;
  width: 0;
}

.product_meta {
  padding: 15px 0;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  border-top: 1px solid #eaebee;
  border-bottom: 1px solid #eaebee;
}

.product_share {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 35px 0;
}

.screen_reader_text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  border: 0;
  clip-path: inset(50%);
  padding: 0;
  margin: -1px;
}

.share_link {
  color: #909097;
  margin-right: 35px;
  font-weight: 600;
  transition: 0.5s;
  text-decoration: none;
  cursor: pointer;
  background-color: initial;
}

.description_tab {
  background: #fff;
  z-index: 2;
  border-bottom-color: #fff;
  border: 0;
  padding: 0 25px;
  border-radius: 4px 4px 0 0;
  margin: 0 -5px;
  display: inline-block;
  position: relative;
}

.tab_link {
  position: relative;
  text-shadow: inherit;
  font-size: 1.2rem !important;
  font-weight: 800 !important;
  display: inline-block;
  padding: 0.5rem 0;
  text-decoration: none;
  transition: 0.5s;
}

.desc_p {
  font-weight: 400;
}

@media (max-width: 766px) {
  .cart_btn {
    margin-top: 1rem;
  }
  .form_btn {
    padding: 2px 6px;
  }
  .form_input {
    margin: 0 0.5rem;
  }
}
@media (max-width: 1440px) {
  .summary {
    padding-left: 50px;
    padding-right: 50px;
  }
  .quantity {
    width: 140px;
  }
}
