.side_menu {
  padding: 20px;
  overflow-y: auto;
  max-width: 100%;
}

.side_menu_inner {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.search_form {
  position: relative;
  margin-bottom: 30px;
}

.sreen_reader_text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip-path: inset(50%);
  margin: -1px;
  padding: 0;
}

.search_field {
  width: 100%;
  padding: 15px 0 14px;
  border-bottom-width: 1px;
  border: 0;
  word-wrap: normal !important;
}

.search_submit {
  position: absolute;
  right: 0;
  top: 12px;
  padding: 0;
  border: none;
  background: 0 0;
}

.mobile_nav {
  flex: 1;
}

.mobile_nav_ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile_nav_li {
  position: relative;
  margin-bottom: 15px;
}

.mobile_menu_bottom {
  margin-top: 3.5rem;
  margin-bottom: 0.5rem;
}

.mobile_menu_bottom_li {
  position: relative;
  padding: 17px 0;
  margin-bottom: 0;
  border-top: 1px solid #f1f2f4;
}

.side_menu_a {
  font-size: 1rem;
  color: #23232c;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
}

.count {
  font-size: 10px;
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 22px;
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -10px;
  background-color: #23232c;
  transition: all 0.5s;
}
