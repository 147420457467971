.page_title {
  color: #23232c;
  line-height: 1;
  text-transform: none;
}

.page_desc {
  font-weight: 600;
  font-size: 0.8rem;
}
@media only screen and (min-width: 1440px) {
  .page_desc {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 2560px) {
  .page_desc {
    font-size: 1.4rem;
  }
}

.product_header {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  transition: opacity 1s ease-out;
}

.product_header_a {
  text-decoration: none;
  transition: transform 1s;
  cursor: pointer;
}

.product_header_img {
  transition: opacity 1s ease-out;
  /* transition: opacity 0.7s; */
  width: 100%;
  height: auto;
  display: block;
  margin: 0 0 1em;
  box-shadow: none;
}

.product_header_btn_section {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 100;
}

.like {
  transition: transform 1s;
  fill: gray;
  stroke: grey;
  stroke-width: 0;
  overflow: hidden;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.liked {
  transition: transform 1s;
  margin-right: 0;
  fill: grey;
  stroke: grey;
  stroke-width: 0;
  overflow: hidden;
  width: 20px;
  height: 20px;
  stroke-width: 0;
}

.buttons {
  text-align: center;
  position: absolute;
  top: 85%;
  left: 35%;
  transition: opacity 1s ease-out;
}

.product__title {
  color: #1e1e23;
  line-height: 1.2;
  font-weight: 700 !important;
  font-size: 1.2rem;
  word-wrap: break-word;
  text-decoration: none !important;
}

.price {
  font-size: 1rem;
  color: #e8730f;
  font-weight: 600;
  line-height: 30px;
  transition: 0.5s;
}
