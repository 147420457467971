.cart_header {
  border-bottom: 0;
  padding: 10px 40px 10px;
  overflow: hidden;
  position: relative;
  opacity: 1;
  background: #fff;
}

.cart_header_title {
  font-size: 32px;
  font-size: 3.2rem;
  font-family: sofia pro, sans-serif;
  color: #23232c;
  font-weight: 700;
  margin: 0;
  line-height: 1;
}

.description_tab {
  font-size: 2rem;
  color: #23232c;
  font-weight: 500;
  padding-bottom: 10px;
}

.tab_link {
  position: relative;
  display: inline-block;
  transition: all 0.5s;
  color: #909097;
}

.tab_link:hover {
  color: #23232c;
}

.no_content {
  font-weight: 600;
}

.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin: 2rem auto;
}

.checkoutProduct {
  display: flex;
  margin: 20px auto;
}

.checkoutProduct__info {
  padding: 20px;
}

.checkoutProduct__image {
  object-fit: contain;
  width: 180px;
  height: 180px;
  padding: auto 0;
}

.checkoutProduct__title {
  font-size: 17px;
  font-weight: 800;
}

.count {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  line-height: 22px;
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
  background-color: #23232c;
  transition: all 0.5s;
}
