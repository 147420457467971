.hm_heading {
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
}

.landing_image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.landing_image {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.banner_wrap {
  overflow: hidden;
  margin: -10px;
}

.banners {
  float: left;
  width: 29.18919%;
  padding-left: 10px;
  padding-right: 10px;
}

.center_banner {
  float: left;
  width: 41.62162%;
  padding-left: 10px;
  padding-right: 10px;
}

.sober_banner {
  position: relative;
  overflow: hidden;
}

.sober_banner_grid {
  padding: 10px 0;
  background: 50% no-repeat;
  background-clip: content-box;
  background-size: auto 100%;
}

.about_head {
  font-size: 18px;
  font-weight: 700;
  color: #23232c;
  line-height: 1.2;
}

.about_p {
  font-size: 13px;
  font-weight: 600;
}
