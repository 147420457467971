.topbar {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 50px;
  background-color: #23232c;
}

.anchor {
  text-decoration: none;
  color: #ffaa68;
}
.anchor:hover,
.anchor:active {
  color: white;
}

.topbar_menu {
  text-align: right;
  list-style: none;
  margin: 0;
  padding: 0;
}

.topbar_menu_first_li {
  padding-left: 0;
  margin-right: 0;
  display: inline-block;
  padding: 0 20px;
  margin: 0;
}

.topbar_menu_li {
  margin-right: 0;
  display: inline-block;
  padding: 0 20px;
  margin: 0;
}

.header {
  top: 10px;
}

.header_container {
  padding-left: 40 px;
  padding-right: 40 px;
}

.row {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}

.logo {
  display: inline-block;
  position: relative;
  line-height: 1;
  color: rgb(35, 35, 44);
  transition: all 0.5s ease 0s;
  padding: auto 10px;
}

.site_branding {
  width: 100%;
  padding: auto 10px;
}
.search_site_container {
  margin-right: 0px;
}
.search_site {
  flex: 1 1 0%;
  width: auto;
  text-align: center;
  vertical-align: center;
  margin-top: -9.5px;
}

.main_navigation {
  width: auto;
  text-align: center;
}

.nav_menu {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-evenly;
}

.header_icon {
  padding-left: 0px;
  list-style: none;
  margin: 0px;
}

.header_right {
  padding-right: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.nav_li {
  margin-right: 40px;
  display: inline-block;
  position: relative;
  text-align: right;
}

.item_wishlist {
  position: relative;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.item_cart {
  position: relative;
  padding-right: 20px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.nav_a {
  font-size: 0.8rem;
  color: #23232c;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  text-decoration: none;
}
.nav_a:hover,
.nav_a:active {
  color: #ffaa68;
}

.count_a {
  text-decoration: none;
  color: #23232c;
}
.count {
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  line-height: 22px;
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
  background-color: #23232c;
  transition: all 0.5s;
}

.offCanvasMenu {
  font-size: 1rem;
  margin-top: 5px;
  color: #23232c;
  font-weight: 600;
  display: block;
}

@media (min-width: 1400px) {
  .search_site_container {
    margin-right: 5rem;
  }
}
@media (min-width: 1080px) {
  .item_wishlist {
    padding-right: 5px;
    margin-right: 5px;
  }
  .item_cart {
    padding-right: 5px;
    margin-right: 5px;
  }
}
@media (max-width: 1080px) {
  .item_wishlist {
    padding-right: 5px;
    margin-right: 5px;
  }
  .item_cart {
    padding-right: 5px;
    margin-right: 5px;
  }
  .nav_li {
    margin-right: 5px;
  }
}

/* Search Modal */
.searchFields {
  transform: translateY(0);
  opacity: 1;
  box-shadow: none;
  text-shadow: none;
  position: relative;
  border: 0;
  padding: 0;
  background: 0 0;
}

.searchField {
  transition: border 0.5s;
  color: #666;
  border-radius: 0;
  font-size: 1.6rem;
  width: 90%;
  border: 0;
  border-color: #666;
  padding: 0;
}

.searchReset {
  box-shadow: none;
  text-shadow: none;
  position: absolute;
  border: 0;
  padding: 0;
  background: 0 0;
  right: 10px;
  top: 11px;
}
