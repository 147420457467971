.footer {
  display: block;
  background: rgb(28, 28, 35);
  margin-bottom: 0px;
  font-size: 0.8rem;
}

.container {
  padding: 0 50px;
}

.footer_top {
  color: #ffffff;
  padding-top: 50px;
}

.widget_menu {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.widget_title {
  color: rgb(255, 180, 117);
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: poppins, sans-serif;
  text-transform: uppercase;
}

.widgets_menu {
  padding: 0px;
  position: relative;
  margin-bottom: 5px;
  list-style: none;
}
.widgets_menu:hover {
  color: rgb(255, 180, 117) !important;
}

.widgets_menu_link {
  color: white;
  text-decoration: none;
}
.widgets_menu_link:hover {
  color: rgb(255, 180, 117) !important;
}

.footer_info {
  font-family: arial narrow;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.84286;
  color: #464547;
}

.footer_info_container {
  padding-top: 33px;
  padding-bottom: 32px;
  padding-left: 40px;
  padding-right: 40px;
}

.footer_widgets,
.footer_image {
  margin: 0px 0px 40px;
}

@media (max-width: 320px) {
  .footer_image {
    margin-left: -2.2rem;
  }
}

.copyright {
  color: rgb(144, 144, 151);
  display: inline-block;
  margin-right: 20px;
}

.footer_social_menu {
  display: inline-block;
}

.socials_menu {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.socials_menu_li {
  display: inline-block;
  padding: 0px 15px;
}

.socials_menu_li > a {
  color: rgb(144, 144, 151);
}
.socials_menu_li > a:hover {
  color: rgb(255, 180, 117) !important;
}

@media (max-width: 425px) {
  .socials_menu_li {
    padding: 0px 10px;
  }
}

.whatsAppContainer {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.whatsAppSpan {
  border-radius: 50px;
  white-space: nowrap;
  padding: 12px 24px;
  color: #ffffff;
  background: #7272ff;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%);
  margin: 0;
  display: inline-block;
}

.whatsAppPopup {
  background: rgb(0, 126, 57);
  color: rgb(0, 126, 57);
  width: 100%;
  display: inline-block;
  padding: 10px 0;
  margin-right: 5rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.whatsAppPopupResponse {
  position: relative;
  border: 1px solid #e6e6e6;
}

.whatsAppPopupResponseInput {
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  padding: 20px 56px 20px 36px;
  margin: 0;
  color: #4e4e4e;
  background-color: #ffffff;
  border: none;
  width: 100%;
  min-height: 59px;
  border-top: 1px solid #e6e6e6;
  outline: none;
}

.whatsAppPopupResponseSend {
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 0;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
