.btn-delivery {
  color: black;
  border-color: #d4af37;
}

.btn-delivery:hover {
  background-color: #d4af37;
}

.btn-delivery-active {
  background-color: #d4af37;
}
