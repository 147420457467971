.top {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: center;
}

.thumbnails {
  display: flex;
  flex-direction: column;
  line-height: 0;
  list-style: none;
  padding-left: 0rem;
}

.thumbnails_li {
  height: 5rem;
}

.thumbnails_a {
  display: block;
}

.thumbnails_img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  object-position: top;
}

.slides {
  overflow: hidden;
  width: 27.5rem;
  height: 26rem;
  list-style: none;
}

.slides_li {
  width: 30rem;
  height: 26rem;
  position: relative;
  z-index: 1;
  width: 100%;
}

.slides_img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  object-position: top;
}

.slides_li:target {
  z-index: 3;
  animation: slide 1s 1;
  -webkit-animation: slide 1s 1;
}

.slides_li:not(:target) {
  animation: hidden 1s 1;
  -webkit-animation: hidden 1s 1;
}

@keyframes slide {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes hidden {
  0% {
    z-index: 2;
  }
  100% {
    z-index: 2;
  }
}

@media (max-width: 425px) {
  .slides {
    height: 10rem;
  }
  .slides_li {
    height: 10rem;
  }
}

@media (max-width: 768px) {
  .slides {
    height: 20rem;
  }
  .slides_li {
    height: 20rem;
  }
}
