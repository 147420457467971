html,
body {
  padding: 0;
  margin: 0;
  font-family: arial narrow, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

.svg-container {
  background-color: #ffa807;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  align-items: center;
  justify-content: center;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
}

.greyBody {
  background-color: #f0f0f0;
}

.imagePadding {
  padding-left: 0px;
}

.square-box {
  min-width: 100vw;
  min-height: 100vh;
}

.half-square-box {
  min-width: 50vw;
  min-height: 50vh;
}

@media only screen and (min-width: 600px) {
  .imagePadding {
    padding-left: 100px;
  }
}

.main_zoom_image {
  width: 19rem;
  height: 14rem;
  transition: transform 0.2s; /* Animation */
}

.zoom_image {
  width: 9.5rem;
  height: 6rem;
  transition: transform 0.2s; /* Animation */
}

.main_zoom_image:hover,
.zoom_image:hover {
  transform: scale(
    1.05
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@media only screen and (min-width: 526px) {
  .main_zoom_image {
    width: 32rem;
    height: 15rem;
  }

  .zoom_image {
    width: 16rem;
    height: 10rem;
  }
}

@media only screen and (min-width: 769px) {
  .main_zoom_image {
    width: 36rem;
    height: 18rem;
  }

  .zoom_image {
    width: 18rem;
    height: 9rem;
  }
}

@media only screen and (min-width: 989px) {
  .main_zoom_image {
    width: 30rem;
    height: 22.2rem;
  }

  .zoom_image {
    width: 14rem;
    height: 11rem;
  }
}

@media only screen and (min-width: 1200px) {
  .main_zoom_image {
    width: 36rem;
    height: 26.2rem;
  }

  .zoom_image {
    width: 18rem;
    height: 13rem;
  }
}

@media only screen and (min-width: 1400px) {
  .main_zoom_image {
    width: 40rem;
    height: 20rem;
  }

  .zoom_image {
    width: 20rem;
    height: 10rem;
  }
}
